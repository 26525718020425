import { styled, Text, useSx, View } from 'dripsy';
import React from 'react';
import { connect } from 'react-redux';
import { Highlight } from 'app/components/typography/highlight';

import { Title } from 'app/components/title';
import { HorizontalSlider } from '../../../components/horizontal-slider';
import { RenderCourseCard } from '../university';
import { ShadowEffectCard } from 'app/features/upload-page/accordion-section';
import { QuillViewer } from 'app/components/quill-viewer';
import { useDim } from 'app/redux/hooks/useDim';

export const CardHeadings = styled(Text)({
  fontWeight: 'bold',
  fontSize: 16,
  padding: 2,
});

const CourseScreenComponent = ({
  user,
  universityObj,
  courseData,
  subjects = { data: [] },
  pageDescription
}: {
  user: any;
  universityObj: any;
  courseData: any;
  subjects?: any;
  pageDescription?:string
}) => {
  const sx = useSx();
  const { width } = useDim();
  return (
    <View style={[sx({ width: '100%' })]}>
      <View
        style={[
          sx({
            flex: 1,
            flexDirection: ['column', 'row'],
            paddingLeft: [0, 5],
            paddingRight: [0, 5],
            marginLeft: [0, 25],
            marginRight: [0, 25],
            backgroundColor: ['#F0F0F0'],
            justifyContent: 'center',
          }),
        ]}
      >
        <View style={[sx({ width: [width, width - 80, width - 200] })]}>
          <View
            style={[
              sx({
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 3,
                paddingBottom: 5,
              }),
            ]}
          >
            <Highlight
              textStyle={{
                fontWeight: 'bold',
                fontSize: 25,
                color: '#000',
                overflow: 'hidden',
              }}
              style={{ alignSelf:'center' }}
            >
              {universityObj?.name}
            </Highlight>

            <Highlight
              highlightColor={['#00A79D', '#1D76BB']}
              style={{
                alignSelf: 'center',
                paddingTop: 3,
              }}
              textStyle={{
                fontWeight: 'bold',
                fontSize: 18,
                color: '#fff',
                overflow: 'hidden',
              }}
              type="brush"
            >
              {courseData?.name}
            </Highlight>
          </View>

          <View
            style={[
              sx({
                minHeight: [150],
                borderRadius: 5,
              }),
            ]}
          >
            <View
              style={{ flex: 1, height: 200, marginTop: 8, marginBottom: 24 }}
            >
              {Object.entries(subjects).sort((e,e1) => e[0].localeCompare(e1[0])).map((each: any[]) => {
                if(each && each[1] && !each[1].length) return <></>;
                return (
                  <View key={each[0]} style={{ paddingVertical: 10 }}>
                    <Title
                      gradientColor={['$secondary', '$secondary']}
                      styles={[
                        sx({
                          height: 50,
                          marginBottom: 3,
                        }),
                      ]}
                      textStyle={[
                        sx({
                          fontSize: 20,
                        }),
                      ]}
                      color="$black"
                    >
                      {each[0]}
                    </Title>
                    <HorizontalSlider
                      url={'search-syllabus-subject'}
                      dataKey="data"
                      initialData={each[1]}
                      displayCount={30}
                      renderItemCard={(d: any) => (
                        <RenderCourseCard
                          id={d.id || d._id}
                          name={`${d.name}`}
                          shortName={d.shortName || d.shortName[0]}
                          href={`/${d.pageUrl}`}
                          key={`s-${d._id}`}
                        />
                      )}
                      convertData={(d: any) => {
                        return {
                          ...d._source,
                        };
                      }}
                      apiClient="search"
                      user={user}
                      extraQuery={{
                        instituteId: universityObj._id || universityObj.id,
                        specializationId: courseData._id,
                        semesterId: each[1][0].academicSessionId,
                      }}
                    />
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      </View>

      {pageDescription || courseData?.description ? (
        <View style={[sx({ margin: 4, padding: 3 })]}>
          <ShadowEffectCard>
            <QuillViewer html={pageDescription || courseData?.description} />
          </ShadowEffectCard>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

const mapStateToProps = ({ user }: any) => ({ user });

export const CourseScreen = connect(mapStateToProps, {})(CourseScreenComponent);
