import { H3 as DH3, useSx } from 'dripsy';
import React, { ReactNode } from 'react';
import { TextProps } from 'react-native';

export interface LNTextProps extends TextProps {
  children?: string | ReactNode;
  target?:string
}


export function H3({ style: styles, ...props }: LNTextProps) {
  const sx = useSx();

  return <DH3 style={[sx({}), styles]} {...props} />;
}
