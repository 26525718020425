import { styled, Text, useSx, View } from 'dripsy';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ShadowEffectCard } from 'app/features/upload-page/accordion-section';
import { QuillViewer } from 'app/components/quill-viewer';
import { FlatGrid } from 'react-native-super-grid';
import { MaterialCard } from 'app/components/card/material-card';
import { config } from 'app/redux/config';
import { makeUseAxiosHook } from 'app/redux/store';
import { Highlight } from 'app/components/typography/highlight';
import { useDim } from 'app/redux/hooks/useDim';
import { getUser } from '../../../redux/memoizedSelectors';

export const CardHeadings = styled(Text)({
  fontWeight: 'bold',
  fontSize: 16,
  padding: 2,
});

const TopicScreenComponent = ({
  topicObj,
  pageDescription,
}: {
  topicObj: any;
  pageDescription?: string;
}) => {
  const sx = useSx();
  const { width } = useDim();

  const [searchList, setSearchList] = useState<{
    data: any[];
    hasMore: boolean;
  }>({
    data: [],
    hasMore: true,
  });

  const [{ data }, refetch] = makeUseAxiosHook('search', {
    manual: true,
  })({
    url: 'search',
    method: 'GET',
  });

  const [onLoad, setOnload] = useState(false);
  useEffect(() => {
    if (onLoad && refetch) {
      setSearchList({ data: [], hasMore: false });
      refetch({
        params: {
          keywords: topicObj.name,
          $skip: 0,
          $limit: 12,
        },
      });
    } else setOnload(true);
  }, [topicObj, onLoad]);

  useEffect(() => {
    if (data)
      setSearchList({
        data: [...searchList.data, ...data.data],
        hasMore: data.hasMore,
      });
  }, [data]);

  return (
    <View style={[sx({ width: '100%' })]}>
      <View
        style={[
          sx({
            flex: 1,
            flexDirection: ['column'],
            paddingLeft: [0, 5],
            paddingRight: [0, 5],
            marginLeft: [0, 25],
            marginRight: [0, 25],
            backgroundColor: ['#F0F0F0'],
            justifyContent: 'center',
          }),
        ]}
      >
        <View style={[sx({ width: [width, width - 80, width - 200] })]}>
          <View
            style={[
              sx({
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 3,
                paddingBottom: 5,
              }),
            ]}
          >
            <Highlight
              textStyle={{
                fontWeight: 'bold',
                fontSize: 25,
                color: '#000',
                overflow: 'hidden',
              }}
              style={{ alignSelf: 'center' }}
            >
              {topicObj?.name}
            </Highlight>
          </View>
        </View>
        <View style={sx({ flex: 1, width: [width, width - 80, width - 200] })}>
          <FlatGrid
            itemDimension={sx({ width: [200, 400] }).width}
            keyExtractor={(item: any) => item._id}
            data={searchList.data}
            renderItem={({ item: { _id, _source } }: any) => {
              return (
                <MaterialCard
                  {...{
                    id: _id,
                    _id: _id,
                    name: `${_source.name}`,
                    src: `${config.baseUrl}${_source.cover}`,
                    type: _source.type,
                    href: `/m/${_id}`,
                    material: { ..._source, _id: _id },
                  }}
                  style={{ maxWidth: 500 }}
                  key={_id}
                  href={`/m/${_source.id}`}
                />
              );
            }}
            onEndReached={() => {
              if (searchList.hasMore)
                refetch({
                  params: {
                    keywords: topicObj.name,
                    $skip: searchList.data.length,
                  },
                });
            }}
            onEndReachedThreshold={0.3}
          />
        </View>
      </View>
      {pageDescription || topicObj?.description ? (
        <View style={[sx({ margin: 4, padding: 3 })]}>
          <ShadowEffectCard>
            <QuillViewer html={pageDescription || topicObj?.description} />
          </ShadowEffectCard>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};


export const TopicScreen = connect(getUser, {})(TopicScreenComponent);
