import React, { forwardRef, useMemo } from 'react';
import { View, Pressable, useSx, Text, FlatList } from 'dripsy';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';

interface TabsProps {
  tabList: any[]; // Wrong - TODO create a interface for Tab and use its array
  onChange: (e: any) => void;
  value: any;
  activeColor: string;
  showBottomBorder: boolean;
  tabStyles?: ViewStyle | any[];
  tabRowStyle?: ViewStyle;
  borderColor?: string;
  headerTextStyle?: TextStyle;
  activeHeaderStyle?: TextStyle;
  contentStyle?: ViewStyle;
  showsHorizontalScrollIndicator?: boolean;
  showNavigationButtons?: boolean;
  navButtonNextIconComponent?: React.ReactNode;
  navButtonPrevIconComponent?: React.ReactNode;
  navButtonPrevStyle?: StyleProp<typeof View>;
  navButtonNextStyle?: StyleProp<typeof View>;
}

const Tabs = forwardRef<any, TabsProps>(
  (
    {
      tabList,
      onChange,
      value,
      tabStyles = {},
      tabRowStyle = {},
      headerTextStyle,
      activeHeaderStyle,
      contentStyle,
      showsHorizontalScrollIndicator = true,
      showNavigationButtons = true,
      navButtonNextStyle,
      navButtonPrevStyle,
      navButtonNextIconComponent,
      navButtonPrevIconComponent,
    },
    ref: any
  ) => {
    const sx = useSx();

    const children = useMemo(() => {
      return tabList.find((item) => item.key === value)?.component || null;
    }, [value, tabList]);

    return (
      <View style={{ flex: 1 }}>
        <View
          style={[
            sx({
              flexDirection: 'row',
              borderBottomWidth: 1,
              borderBottomColor: '$gray',
            }),
            tabRowStyle,
          ]}
        >
          <FlatList
            initialNumToRender={5}
            initialScrollIndex={0}
            ref={ref}
            nestedScrollEnabled={true}
            data={tabList && tabList.length ? tabList : []}
            horizontal={true}
            showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
            onScrollToIndexFailed={(info) => {
              const wait = new Promise((resolve) => setTimeout(resolve, 500));
              wait.then(() => {
                ref.current?.scrollToIndex({
                  index: info.index,
                  animated: true,
                });
              });
            }}
            renderItem={({ item, index }: any) => {
              return (
                <Pressable
                  key={index}
                  disabled={item.isDisabled}
                  onPress={() => onChange(item.key)}
                  style={[
                    sx({
                      py: 2,
                      fontWeight: 'bold',
                    }),

                    tabStyles,
                  ]}
                >
                  <Text
                    style={[
                      item.key === value ? activeHeaderStyle : headerTextStyle,
                    ]}
                  >
                    {item.label}
                  </Text>
                </Pressable>
              );
            }}
          />
          {showNavigationButtons && (
            <>
              <Pressable
                onPress={() => ref.current?.scrollToOffset({ offset: -300 })}
                style={[
                  sx({
                    position: 'absolute',
                    left: 0,
                    width: 40,
                    height: 40,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }),
                  navButtonPrevStyle,
                ]}
              >
                {navButtonPrevIconComponent ? (
                  navButtonPrevIconComponent
                ) : (
                  <MaterialIcons name="arrow-left" color="white" size={30} />
                )}
              </Pressable>
              <Pressable
                onPress={() => ref.current?.scrollToOffset({ offset: 300 })}
                style={[
                  sx({
                    position: 'absolute',
                    right: 0,
                    width: 40,
                    height: 40,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }),
                  navButtonNextStyle,
                ]}
              >
                {navButtonNextIconComponent ? (
                  navButtonNextIconComponent
                ) : (
                  <MaterialIcons name="arrow-right" color="white" size={30} />
                )}
              </Pressable>
            </>
          )}
        </View>

        <View style={{ flex: 1 }}>
          <View
            style={[
              sx({
                padding: 2,
                flex: 1,
              }),
              contentStyle,
            ]}
          >
            {children}
          </View>
        </View>
      </View>
    );
  }
);

Tabs.displayName = 'Tabs';

export default Tabs;
