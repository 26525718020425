import { Gradient } from 'dripsy/gradient';
import { SearchBox } from 'app/components/search';
import { styled, Text, useSx, View } from 'dripsy';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { createParam } from 'solito';
import { useRouter } from 'solito/router';
import { makeUseAxiosHook } from 'app/redux/store';
import Layout from '../../features/layout';
import { UniversityScreen } from './university';
import { SubjectScreen } from './subject';
import { CourseScreen } from './course';
import { Highlight } from 'app/components/typography/highlight';
import { TopicScreen } from './topic';
import { ChapterScreen } from './chapter';
import { A } from 'app/components/typography/a';
import { Loader } from 'app/components/loader';
import { PageScreen } from './page';
import { materialName } from 'app/util/helper';
import CompetitiveExams from './exam-syllabus/competitive-exams';

const { useParam } = createParam<{
  slug: string;
  'sub-slug'?: string;
  'nested-sug'?: string;
}>();

export const CardHeadings = styled(Text)({
  fontWeight: 'bold',
  fontSize: 16,
  padding: 2,
});

export const SlugScreen=({
  institute: _institute,
  specialization: _specialization,
  subject: _subject,
  chapter: _chapter,
  topic: _topic,
  page: _page,
  contentPages: _contentPages,
  coursesOffered,
  subjects,
  chapters,
  topics,
  pageType: _pageType,
  seoData,
  notFound,
  isNavbar,
})=>{
  const [slug] = useParam('slug');
  const [slug2] = useParam('sub-slug');
  const [slug3] = useParam('nested-sug');
  const sx = useSx();
  const { push } = useRouter();
  const [searchParams] = useReducer((p: any, a: any) => ({ ...p, ...a }), {});

  const [institute, setInstitute] = useState(_institute);
  const [specialization, setSpecialization] = useState(_specialization);
  const [subject, setSubject] = useState(_subject);
  const [chapter, setChapter] = useState(_chapter);
  const [topic, setTopic] = useState(_topic);
  const [examSyllabus, setExamSyllabus] = useState<any>({});

  const [pageType, setPageType] = useState(_pageType);

  useEffect(() => {
    _institute && setInstitute(_institute);
    _specialization && setSpecialization(_specialization);
    _subject && setSubject(_subject);
    _pageType && setPageType(_pageType);
  }, [_institute, _specialization, _subject, _page]);

  const [{ data: slugFetchedData, loading }, fetchSlugData] = makeUseAxiosHook(
    'academic',
    { manual: true }
  )({ url: '/page/v1?deleted=false', method: 'GET' });

  const url = useMemo(() => {
    return `${slug}${slug2 ? `/${slug2}` : ''}${
      slug2 && slug3 ? `/${slug3}` : ''
    }`;
  }, [slug, slug2, slug3]);

  useEffect(() => {
    if (url)
      fetchSlugData({
        params: {
          url,
        },
      });
  }, [url]);

  useEffect(() => {
    if (slugFetchedData && slugFetchedData.exist) {
      setPageType(slugFetchedData?.type);
      slugFetchedData?.objects?.forEach((element: any) => {
        if (['institute'].includes(element.type)) {
          setInstitute({
            ...element,
            object: element[element.type],
            exist: true,
          });
        }
        if (['specialization'].includes(element.type)) {
          setSpecialization({
            ...element,
            object: element[element.type],
            exist: true,
          });
        }
        if (['subject'].includes(element.type)) {
          setSubject({
            ...element,
            object: element[element.type],
            exist: true,
          });
        }
        if (['chapter'].includes(element.type)) {
          setChapter({ ...element, object: element[element.type] });
        }
        if (['topic'].includes(element.type)) {
          setTopic({ ...element, object: element[element.type], exist: true });
        }
        if (['competitiveExam'].includes(element.type)) {
          setExamSyllabus({ ...element, object: element[element.type], exist: true });
        }
      });
    }
  }, [slugFetchedData]);

  const [keyword, setKeyword] = useState('');

  const redirectTo = (obj) => {
    const { type, _id, name, query } = obj;
    if (type === 'material' || type === 'general_material') {
      push(`/m/${_id}`);
    } else {
      push(
        `/search/${encodeURIComponent(name)}?${
          query
            ? Object.entries(query)
                .map((o) => `${o[0]}=${o[1]}`)
                .join('&')
            : ''
        }`
      );
    }
  };

  const showErrorPage = useMemo(() => {
    return !pageType || notFound;
  }, [pageType]);

  if (loading && pageType!=='page') return <Loader />;

  return (
    <Layout
      showFullFooter
      leftMenu={false}
      leftMenuProps={{
        showUserInfo: false,
        showAdminConsole: false,
      }}
      isNavBar={isNavbar}
    >
      <View style={[sx({ width: '100%', flex: 1 })]}>
        {typeof pageType!=='undefined' && pageType !== 'page' && (
          <Gradient
            colors={['$secondary', '$primary']}
            style={sx({
              paddingY: 70,
              justifyContent: 'center',
              alignItems: 'center',
              paddingX: 10,
              height: 200,
              width: '100%',
            })}
          >
            <SearchBox
              styles={sx({ flex: 1, maxWidth: 800, width: '100%' })}
              displayKey={(material) =>materialName(material)}
              placeholder={'Search here'}
              onChangeTexts={(d) => {
                setKeyword(d);
              }}
              value={searchParams.slug}
              onEnterClick={() =>
                redirectTo({ name: keyword, query: { ...searchParams } })
              }
              url="search"
              apiClient="search"
              searchKey="keywords"
              convertData={(d: any) =>
                d.data.data.map((r: any) => ({ ...r, ...r._source }))
              }
              onSelectData={(e) => redirectTo(e)}
            />
          </Gradient>
        )}

        {pageType == 'institute' ? (
          <UniversityScreen
            universityObj={institute.object}
            coursesOffered={coursesOffered}
            pageDescription={seoData?.description}
          />
        ) : (
          <></>
        )}

        {pageType == 'course' ? (
          <CourseScreen
            universityObj={institute.object}
            courseData={specialization.object}
            subjects={subjects}
            pageDescription={seoData?.description}
          />
        ) : (
          <></>
        )}

        {pageType == 'subject' ? (
          <SubjectScreen
            subjectObj={subject.object}
            chapters={chapters}
            universityId={institute.object._id}
            specializationId={specialization.object._id}
            pageDescription={seoData?.description}
          />
        ) : (
          <></>
        )}

        {pageType == 'chapter' ? (
          <ChapterScreen
            subjectId={subject?.object?._id}
            chapterObj={chapter.object}
            topics={topics}
            universityId={institute.object._id}
            specializationId={specialization.object._id}
            pageDescription={seoData?.description}
          />
        ) : (
          <></>
        )}

        {pageType == 'topic' ? (
          <TopicScreen
            topicObj={topic.object}
            pageDescription={seoData?.description}
          />
        ) : (
          <></>
        )}
        {pageType == 'competitiveExam' ? (
          <CompetitiveExams examSyllabus={examSyllabus}/>
        ) : (
          <></>
        )}

        {
          pageType === 'page' && !showErrorPage && (
            loading ? <Loader /> : (
              <PageScreen
                pageTitle={seoData?.pageTitle}
                pageType={pageType}
                slug={url}
                id={slugFetchedData?._id}
                contentPages={_contentPages ? _contentPages : slugFetchedData?.contentPages}
              />
            )
          )
        }
       
        {pageType && showErrorPage ? (
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Highlight
              type="brush"
              textStyle={{ fontSize: 24, fontWeight: 'bold' }}
              highlightColor={['red']}
              style={{ alignSelf: 'center', marginBottom: 30 }}
            >
              404
            </Highlight>
            <Highlight
              type="brush"
              textStyle={{ fontSize: 14, fontWeight: 'bold' }}
              style={{ alignSelf: 'center', marginBottom: 10 }}
            >
              Page <A href="/">{slug}</A> not Found - 404
            </Highlight>
            <Highlight
              style={{ alignSelf: 'center', marginBottom: 30 }}
              type="brush"
              textStyle={{ fontSize: 14, fontWeight: 'bold' }}
            >
              <A href="/">Redirect to Home</A>
            </Highlight>
          </View>
        ) : (
          <></>
        )}
      </View>
    </Layout>
  );
};
