import { Gradient } from 'dripsy/gradient';
import { MaterialCard } from 'app/components/card/material-card';
import { Icon } from 'app/components/icon/icon';
import { Title } from 'app/components/title';
import { makeUseAxiosHook } from 'app/redux/store';
import { cleanObject, uuidv4 } from 'app/util/helper';
import { FlatList, styled, useSx, View } from 'dripsy';
import React, { useEffect, useReducer, useRef } from 'react';
import { useState } from 'react';
import { Pressable } from 'react-native';

interface HorizontalMaterialScrollProps {
  url: string;
  displayCount?: number;
  dataKey?: string;
  convertData?: (d: any) => any;
  title?: string;
  apiClient?: string;
  user?: any;
  preference?: any;
  renderItemCard?: any;
  extraQuery?: any;
  initialData?: any[];
  queryParams?: any;
  noPagination?: boolean;
}

const IconButtonLeft = styled(Pressable)({
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  justifyContent: 'center',
});

const ScrollLeftIcon = styled(Icon)({
  borderRadius: 35,
  height: 60,
  width: 50,
  alignItems: 'center',
  justifyContent: 'center',
});

const ScrollRightIcon = styled(Icon)({
  borderRadius: 35,
  height: 60,
  width: 50,
  alignItems: 'center',
  justifyContent: 'center',
  transform: [{ rotate: '180deg' }],
});

const IconButtonRight = styled(Pressable)({
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  right: 0,
  justifyContent: 'center',
});

export function HorizontalSlider({
  url,
  displayCount: limit = 10,
  dataKey = 'data',
  convertData = (d: any) => d,
  title,
  apiClient = 'content',
  user,
  preference,
  renderItemCard,
  extraQuery,
  initialData = [],
  queryParams = undefined,
  noPagination = false,
}: HorizontalMaterialScrollProps) {
  const [skip, incrementSkip] = useReducer(
    (s: number) => s + limit,
    initialData.length
  );
  const [contentOffset, setContentOffset] = useState<number>(0);
  const ref = useRef<any>();
  const sx = useSx();
  const [data, setData] = useReducer(
    (state: { data?: any[]; hasMore?: boolean }, action: any): any => {
      if (action && action[dataKey])
        return {
          ...(state || {}),
          data: [
            ...(noPagination ? [] : state?.data ?? []),
            ...(action[dataKey]?.map((d: any) => convertData(d)) || []),
          ],
          hasMore: action.hasMore === true || action[dataKey].length || false,
        };
      else {
        return state;
      }
    },
    { data: initialData, hasMore: true }
  );

  const id = uuidv4();

  const getQuery = (preferenceData) => {
    let query = {
      organizationId: '',
      courseId: '',
      specializationId: '',
      academicSessionId: '',
    };
    if (preferenceData) {
      query = {
        organizationId:
          preferenceData.organizationId && !isNaN(preferenceData.organizationId)
            ? preference.organizationId
            : '',
        courseId:
          preferenceData.courseId && !isNaN(preferenceData.courseId)
            ? preferenceData.courseId
            : '',
        specializationId:
          preferenceData.specializationId &&
          !isNaN(preferenceData.specializationId)
            ? preferenceData.specializationId
            : '',
        academicSessionId:
          preferenceData.academicSession &&
          !isNaN(preferenceData.academicSession)
            ? preferenceData.academicSession
            : '',
      };
    }

    return query;
  };

  const useFetch = makeUseAxiosHook(apiClient, {
    manual: noPagination,
  });

  const [{ data: fetchedData, loading, error }, refetch] = useFetch(
    {
      url,
      method: 'GET',
      params: queryParams,
    },
    { skip, limit, username: user?.username || '', ...cleanObject(extraQuery) }
  );

  useEffect(() => {
    if(!loading){
      setData(fetchedData);
    }
  }, [fetchedData, loading, error]);

  useEffect(() => {
    if (!noPagination) {
      if (apiClient === 'search') {
        refetch({
          params: preference && getQuery(preference),
        });
      } else {
        refetch();
      }
    }
  }, [user, preference]);

  const scrollLeft = () => {
    ref.current?.scrollToOffset({
      offset: contentOffset - 300,
      animated: true,
    });
  };

  const scrollRight = () => {
    ref.current?.scrollToOffset({
      offset: contentOffset + 300,
      animated: true,
    });
  };

  return data?.data.length ? (
    <View
      style={[
        sx({
          minHeight: [150],
        }),
      ]}
    >
      {title ? (
        <Title
          gradientColor={['$secondary', '$secondary']}
          styles={[
            sx({
              marginY: 3,
              height: 40,
            }),
          ]}
          color="$black"
        >
          {title}
        </Title>
      ) : null}
      <View style={{ flex: 1, justifyContent: 'space-evenly', height: 250 }}>
        <IconButtonLeft
          disabled={contentOffset === 0}
          onPress={() => scrollLeft()}
          style={[sx({ width: [0, 30], display: ['none', 'flex'] })]}
        >
          <Gradient
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 1 }}
            colors={['#999999ff', '#99999910']}
            style={sx({
              flex: 1,
              padding: ['$0', '$1'],
              alignItems: 'center',
              justifyContent: 'center',
              height: 35,
            })}
          >
            <ScrollLeftIcon
              name="leftIcon"
              fill={['white']}
              style={[sx({ width: [0, 30], display: ['none', 'flex'] })]}
            />
          </Gradient>
        </IconButtonLeft>
        <FlatList
          horizontal={true}
          // scrollEnabled={true}
          showsVerticalScrollIndicator={false}
          initialNumToRender={limit}
          ref={ref}
          initialScrollIndex={0}
          onScroll={(e) => setContentOffset(e.nativeEvent.contentOffset.x)}
          renderItem={({ item }: { item: any; index: number }) => {
            return renderItemCard ? (
              renderItemCard(item)
            ) : (
              <MaterialCard
                {...item}
                totalPages={item.totalItem}
                covered={item.lastReadItem}
                material={item}
                _id={item.id ? item.id : item._id}
                id={item.id ? item.id : item._id}
                src={item.thumb}
                name={item.name}
                style={sx({ width: [320, 350] })}
                href={`/m/${item.id || item._id}`}
              />
            );
          }}
          data={data?.data}
          keyExtractor={(item: any, index: number) =>
            `key-materialCard-${id}-${item._id || item.id || index}`
          }
          onEndReachedThreshold={0.2}
          onEndReached={() => {
            if(data.hasMore===true && !loading){
              incrementSkip();
            }
          }}
        />
        <IconButtonRight
          onPress={() => scrollRight()}
          style={[sx({ width: [0, 30], display: ['none', 'flex'] })]}
        >
          <Gradient
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 1 }}
            colors={['#99999910', '#999999ff']}
            style={sx({
              flex: 1,
              padding: ['$0', '$1'],
              alignItems: 'center',
              justifyContent: 'center',
              height: 35,
            })}
          >
            <ScrollRightIcon
              name="leftIcon"
              fill={['white']}
              style={[sx({ width: [0, 30], display: ['none', 'flex'] })]}
            />
          </Gradient>
        </IconButtonRight>
      </View>
    </View>
  ) : null;
}
