import { useSx, View } from 'dripsy';
import React from 'react';
import { Platform, ViewStyle } from 'react-native';
import { Title } from '../../components/title';
import { I18Text } from 'app/provider/i18-provider';

interface CardProps {
  backgroundColor?: string;
  styles?: ViewStyle | any[];
  cardContentRoot?: ViewStyle;
  children?: React.ReactNode;
  title?: any
}

export function Card({
  backgroundColor = '$white',
  styles = {},
  cardContentRoot = {},
  children,
  title,
}: CardProps) {
  const sx = useSx();
  return (
    <View
      style={[
        sx({
          ...Platform.select({
            default: {
              backgroundColor: `${backgroundColor}`,
              borderRadius: 10,
              position: 'relative',
            },
            web: {
              backgroundColor: `${backgroundColor}`,
              borderColor: '$black',
              borderWidth: 1,
              borderStyle: 'solid',
              // boxShadow: '10px 10px 17px -12px rgba(0,0,0,0.75)',
              borderRadius: 10,
            },
            native: {
              backgroundColor: `${backgroundColor}`,
              borderColor: '$black',
              borderWidth: 1,
              borderStyle: 'solid',
              borderRadius: 10,
              elevation: 10,
            },
          }),
        }),
        styles,
      ]}
    >
      {title ? (
        <Title
          color="white"
          backgroundColor="#abe9cd"
          styles={{
            top: -15,
            left: 15,
            backgroundImage:
              'linear-gradient(315deg,rgb(114 187 154) 0%, rgb(109 179 201) 74%)',
          }}
        >
          <I18Text>{title}</I18Text>
        </Title>
      ) : (
        <></>
      )}
      <View
        style={[
          sx({
            padding: 2,
            paddingTop:0
          }),
          cardContentRoot,
        ]}
      >
        {children}
      </View>
    </View>
  );
}
