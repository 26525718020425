import { styled, Text, useSx, View } from 'dripsy';
import React from 'react';
import { connect } from 'react-redux';

import { HorizontalSlider } from '../../../components/horizontal-slider';
import { RenderCourseCard } from '../university';
import { ShadowEffectCard } from 'app/features/upload-page/accordion-section';
import { QuillViewer } from 'app/components/quill-viewer';
import { Highlight } from 'app/components/typography/highlight';
import { useDim } from 'app/redux/hooks/useDim';

export const CardHeadings = styled(Text)({
  fontWeight: 'bold',
  fontSize: 16,
  padding: 2,
});

const ChapterScreenComponent = ({
  user,
  chapterObj,
  topics = { data: [] },
  universityId,
  specializationId,
  subjectId,
  pageDescription
}: {
  user: any;
  subjectId: any;
  chapterObj: any;
  topics?: any;
  universityId?: string,
  specializationId?: string
  pageDescription?:string
}) => {
  const sx = useSx();
  const { width } = useDim();

  return (
    <View style={[sx({ width: '100%' })]}>
      <View
        style={[
          sx({
            flex: 1,
            flexDirection: ['column', 'row'],
            paddingLeft: [0, 5],
            paddingRight: [0, 5],
            marginLeft: [0, 25],
            marginRight: [0, 25],
            backgroundColor: ['#F0F0F0'],
            justifyContent: 'center',
          }),
        ]}
      >
        <View style={[sx({ width: [width, width - 80, width - 200] })]}>
          <View
            style={[
              sx({
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 3,
                paddingBottom: 5,
              }),
            ]}
          >
            <Highlight
              textStyle={{
                fontWeight: 'bold',
                fontSize: 25,
                color: '#000',
                overflow: 'hidden',
              }}
              style={{ alignSelf:'center' }}
            >
              {chapterObj?.name}
            </Highlight>
          </View>

          <View
            style={[
              sx({
                minHeight: [150],
                borderRadius: 5,
              }),
            ]}
          >
            <View
              style={{ flex: 1, height: 300, marginTop: 8, marginBottom: 24 }}
            >
              <HorizontalSlider
                url={'search-syllabus'}
                dataKey="data"
                initialData={topics?.data}
                displayCount={30}
                renderItemCard={(d: any) => {
                  return (
                    <RenderCourseCard
                      id={d._id || d.id}
                      name={`${d.name}`}
                      shortName={d.shortName}
                      href={`/${d.pageUrl}`}
                      key={`s-${d._id}`}
                    />
                  );
                }}
                convertData={(d: any) => {
                  return {
                    ...d._source,
                  };
                }}
                apiClient="search"
                user={user}
                extraQuery={{ type: 'topic', chapterId:chapterObj._id, subjectId, instituteId: universityId, specializationId }}
              
              />
            </View>
          </View>
        </View>
      </View>

      {pageDescription || chapterObj?.description ? (
        <View style={[sx({ margin: 4, padding: 3 })]}>
          <ShadowEffectCard>
            <QuillViewer html={pageDescription || chapterObj?.description} />
          </ShadowEffectCard>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

const mapStateToProps = ({ user }: any) => ({ user });

export const ChapterScreen = connect(
  mapStateToProps,
  {}
)(ChapterScreenComponent);
