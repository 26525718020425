import { View, styled, useSx } from 'dripsy';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'app/components/accordion';
import { Highlight } from 'app/components/typography/highlight';
import { Card } from 'app/components/card';
import { makeUseAxiosHook } from 'app/redux/store';
import { Empty } from 'app/components/Empty';
import { QuillViewer } from 'app/components/quill-viewer';
import { I18Text } from 'app/provider/i18-provider';

export const ShadowEffectCard = styled(View)({
  backgroundColor: '#FFF',
  borderColor: '#dbdddf',
  borderStyle: 'solid',
  borderWidth: 1.5,
  padding: 10,
  margin: 10,
  borderRadius: 5,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 4,
  elevation: 5,
});

export function AccordionSection({ guidelineRef }: { guidelineRef?: any}) {
  const sx = useSx();

  //const []

  const [faqList, setFaqList] = useState({
    data: [],
    loading: false,
    hasMore: true,
  });
  const [categoryData,setCategoryData] = useState<any>({ data:{} });

  const [{ data:category,error:categoryError }] = makeUseAxiosHook('faq')(
    {
      url:'faq-category',
      method: 'GET',

    },
    {
      type:'upload'
    }
  );
  useEffect(()=>{
    if(categoryError){
      return;
    }
    if(category && !categoryError){
      setCategoryData({ ...categoryData,data:category.data[0] });
    }
  },[categoryError,category]);

  const [{ data, error }] = makeUseAxiosHook('faq')(
    {
      url:'faq-list-by-categoryid',
      method: 'GET',
    },
    { categoryId:categoryData.data && categoryData.data._id,skip:0 }
  );
  useEffect(()=>{
    if(error){
      return;
    }
    if(data && !error){
      setFaqList({ ...faqList,data:data.data,hasMore:data.hasMore });
    }
  },[error,data]);
  
  return (
    <View ref={guidelineRef} style={[sx({ marginTop: 30, padding: 10 })]}>
      <Card styles={[sx({ position: 'relative', borderColor: '$primary',borderRadius:5 })]}>
        <Highlight style={[sx({ position: 'absolute', left: 25, top: -15,flexWrap:'wrap' })]}>
         <I18Text>FAQ</I18Text> 
        </Highlight>
        <View style={[sx({ marginTop: 30 })]}>
          {faqList && faqList.data.length?
            faqList.data.map((item: any, index: number) => {
              return (
                <ShadowEffectCard key={index}>
                  <Accordion
                    title={item.question}
                    contents={<QuillViewer html={item.answer}/>}
                    icon={undefined}
                  />
                </ShadowEffectCard>
              );
            }):<Empty/>}
        </View>
      </Card>
    </View>
  );
}
