import { Gradient } from 'dripsy/gradient';
import { useSx, Text } from 'dripsy';
import React from 'react';
import { Platform, ViewProps } from 'react-native';

interface TitlePage extends ViewProps {
  children: any
  backgroundColor?: string
  color?: string
  isGradient?: boolean
  gradientColor?: string[]
  styles?: object
  textStyle?: object
}

export function Title({
  children,
  backgroundColor = '$secondary',
  color = '$white',
  styles = {},
  textStyle = {},
  isGradient = true,
  gradientColor = ['$primary', '$secondary'],
}: TitlePage) {
  const sx = useSx();
  return (
    <Gradient
      colors={isGradient ? gradientColor : [backgroundColor, backgroundColor]}
      style={[
        sx({
          ...Platform.select({
            web: {
              backgroundColor: `${backgroundColor}`,
              display: 'flex',
              width: ['80%', 'fit-content'],
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 35,
              clipPath: 'polygon(2% 2%, 98% 5%, 100% 75%, 0% 94%)',
            },
            native: {
              backgroundColor: `${backgroundColor}`,
              display: 'flex',
              width: ['80%', '100%'],
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 35,
            },
          }),
        }),
        styles,
      ]}
    >
      <Text
        style={[
          sx({
            textAlign: 'center',
            fontWeight: 'bold',
            paddingRight: 3,
            paddingLeft: 3,
            color: `${color}`,
          }),
          textStyle,
        ]}
      >
        {children}
      </Text>
    </Gradient>
  );
}