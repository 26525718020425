
import { SlugScreen } from 'app/features/slug-pages';
import { client } from 'app/redux/store';
import React from 'react';
import { SeoHelper } from 'app/components/seo-helper';
import { config } from 'app/redux/config';
import { getServerSideSitemap } from 'next-sitemap';
function SlugPage(props: any) {
  return (
    !props.isSitemapPage ? <div>
      <SeoHelper
        title={`${props?.seoData?.metaTitle || props?.seoData?.ogTitle ||
          props.institute?.object?.name ||
          props.specialization?.object?.name ||
          props.subject?.object?.name ||
          props.chapter?.object?.name ||
          props.topic?.object?.name ||
          'Lecture Notes | Handwritten Notes & Study Materials Online'
          }`}
        description={`${props?.seoData?.metaDescription || props?.seoData?.ogDescription ||
          props?.institute?.object?.description ||
          props?.specialization?.object?.description ||
          props?.subject?.object?.description ||
          props?.chapter?.object?.description ||
          props?.topic?.object?.description ||
          'Lecture Notes brings all your study material online and enhances your learning journey. Our team will help you for exam preparations with study notes and previous year papers.'
          }`}
        image={`${props?.seoData?.ogImage ||
          'https://upload-production-s3.s3.ap-south-1.amazonaws.com/lecturenotes-assets/ln-og-image.png'
          }`}
        keywords={`${props?.seoData?.metaKeywords ||
          'engineering notes, lecture notes, computer science notes, electronics notes, civil notes, mechanical notes, electrical notes, previous year questions, , notes, university notes, exam notes, class notes, written notes, toppers notes, notes app, exam preparation, Revision Notes, study for exams, pdf notes, study material pdf'
          }`}
        ogDescription={`${props?.seoData?.ogDescription ||
          'Lecture Notes brings all your study material online and enhances your learning journey. Our team will help you for exam preparations with study notes and previous year papers.'
          }`}
        ogTitle={`${props?.seoData?.ogTitle ||
          'Lecture Notes | Handwritten Notes & Study Materials Online'
          }`}
      ></SeoHelper>
      <SlugScreen {...props}></SlugScreen>
    </div> : null
  );
}

SlugPage.getInitialProps = async (ctx: any) => {
  const isMobileApp = ctx?.query?.isMobileApp; // if page is open on android app
  let slugData: any = { exist: false };
  let isSitemapPage = false;
  let sitemapSlug: string | null = null;
  let sitemapPage: number | null = 0;
  if (ctx.asPath) {
    isSitemapPage = ctx.asPath.replace('/', '', 1).match(/sitemap\-\d+\.xml/) ? true : false;
    sitemapSlug = isSitemapPage ? ctx.asPath.replace('/', '', 1).match(/sitemap\-\d+\.xml/)[0] : null;
    // @ts-ignore
    sitemapPage = isSitemapPage && sitemapSlug && sitemapSlug.match(/\d+/g)?.length ? Number(sitemapSlug.match(/\d+/g)[0]) : null;
    slugData = !isSitemapPage ? await client['academic'].client
      .get(`/page/v1?url=${ctx.asPath.replace('/', '', 1)}&deleted=false`)
      .then((r: { data: any }) => r.data)
      .catch((e: any) => {
        console.error(e);
        return null;
      }) : { exists: false };
  }
  if (isSitemapPage && sitemapPage) {
    const sitemapURLsLimitPerPage = 50000;
    const skip = (sitemapPage - 1) * sitemapURLsLimitPerPage;
    const res = await fetch(`${config.academicsBaseUrl}/seo-sitemaps?url=${encodeURI(config.baseUrl)}&$limit=${sitemapURLsLimitPerPage}&$skip=${skip}`);
    const sitemapFields = await res.json();
    return getServerSideSitemap(ctx, sitemapFields);
  }
  const data: any = {
    pageType: '',
    seoData: {},
    institute: {},
    specialization: {},
    subject: {},
    chapter: {},
    topic: {},
    page: {},
    coursesOffered: [],
    subjects: [],
    chapters: [],
    topics: [],
    contentPages: [],
    notFound: false,
    isSitemapPage: false,
    sitemapPage: null,
    sitemapSlug: null,
    sitemapFields: {},
    isNavbar:!isMobileApp,
    isSideMenu:!isMobileApp
  };

  if (slugData?.exist) {
    data.pageType = slugData.type;
    const { objects, ...otherData } = slugData;
    data.seoData = otherData;
    objects?.forEach((element: any) => {
      if (['institute'].includes(element.type)) {
        data.institute = {
          ...element,
          object: element[element.type],
          exist: true,
        };
      }
      if (['specialization'].includes(element.type)) {
        data.specialization = {
          ...element,
          object: element[element.type],
          exist: true,
        };
      }
      if (['chapter'].includes(element.type)) {
        data.chapter = {
          ...element,
          object: element[element.type],
          exist: true,
        };
      }

      if (['subject'].includes(element.type)) {
        data.subject = { ...element, object: element[element.type] };
      }

      if (['topic'].includes(element.type)) {
        data.topic = { ...element, object: element[element.type], exist: true };
      }
    });
  }
  if (data.institute.type === 'institute') {
    if (!data.specialization || !data.specialization.exist) {
      // University page - fetch courses & trending data
      try {
        data.coursesOffered = await client['search'].client
          .get(
            `/api/v1/search?type=specialization&instituteId=${data.institute?.object?._id}`
          )
          .then(
            (r: { data: any }) =>
              r.data.data.map((d: any) => ({ ...d._source })) as any[]
          );
      } catch (error:any) { }
    } else {
      // Course page - fetch subject information
      const subjectArr = await client['search'].client
        .get(
          `/api/v1/search?type=subject&instituteId=${data.institute?.object?._id}&specializationId=${data.specialization?.object?._id}`
        )
        .then(
          (r: { data: any }) =>
            r.data.data.map((d: any) => ({ ...d, ...d._source })) as any[]
        );
      data.subjects = subjectArr.reduce(
        (sem: any, subject: { academicSessionName: any }) => ({
          ...sem,
          [subject.academicSessionName]: [
            ...(sem[subject.academicSessionName]
              ? sem[subject.academicSessionName]
              : []),
            subject,
          ],
        }),
        {}
      );
    }
  }
  if (data.subject.type === 'subject') {
    data.chapters = await client['search'].client
      .get(
        `/api/v1/search?type=chapter&subjectId=${data.subject?.object?._id}${data.institute?.object?._id
          ? `&instituteId=${data.institute?.object?._id}`
          : ''
        }${data.specialization?.object?._id
          ? `&specializationId=${data.specialization?.object?._id}`
          : ''
        }`
      )
      .then((r: { data: any }) =>
        (r.data.data as any[]).map((d) => ({ ...d, ...d._source }))
      );
    if (data.topic && data.topic.type === 'topic') {
      data.topics = await client['search'].client
        .get(
          `/api/v1/search?type=topic&subjectId=${data.subject?.object?._id
          }&chapterId=${data.chapter?.object?._id}${data.institute?.object?._id
            ? `&instituteId=${data.institute?.object?._id}`
            : ''
          }${data.specialization?.object?._id
            ? `&specializationId=${data.specialization?.object?._id}`
            : ''
          }`
        )
        .then((r: { data: any }) =>
          (r.data.data as any[]).map((d) => ({ ...d, ...d._source }))
        )
        .catch((e: any) => {
          console.error(e);
          return [];
        });
    }
  }

  if (data.pageType === 'page') {
    if (slugData.contentPages) {
      data.contentPages = slugData.contentPages;
    } else {
      data.notFound = true;
    }
  }

  // if (
  //   (ctx.query.slug && !data.slug1Data.exist) ||
  //   (ctx.query['sub-slug'] && !data.slug2Data.exist) ||
  //   (ctx.query['nested-sug'] && !data.slug3Data.exist)
  // ) {
  //   if (ctx.res) {
  //     ctx.res.statusCode = 404;
  //   }
  // }
  return {
    ...data,
  };
};

export default SlugPage;
