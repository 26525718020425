import { Row, styled, Text, useSx, View } from 'dripsy';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { HorizontalSlider } from '../../../components/horizontal-slider';
import { ShadowEffectCard } from 'app/features/upload-page/accordion-section';
import { QuillViewer } from 'app/components/quill-viewer';
import { FlatGrid, RenderCourseCard } from '../university';
import { makeUseAxiosHook } from 'app/redux/store';
import { MaterialCard } from 'app/components/card/material-card';
import { Button } from 'app/components/button/common-button';
import { Highlight } from 'app/components/typography/highlight';
import { useDim } from 'app/redux/hooks/useDim';
import { getUser } from '../../../redux/memoizedSelectors';

export const CardHeadings = styled(Text)({
  fontWeight: 'bold',
  fontSize: 16, 
  padding: 2,
});

const SearchSubjectComponent = ({
  user,
  subjectObj,
  chapters,
  universityId,
  specializationId,
  pageDescription
}: {
  user: any;
  subjectObj: any;
  chapters: any;
  universityId?: string;
  specializationId?: string;
  pageDescription?:string
}) => {
  const sx = useSx();
  const { width } = useDim();
  const [trendingNotes, setTrendingNotes] = useState<any>({
    data: [],
    loading: false,
    hasMore: true,
  });

  const [{ data: trendingData, loading, error }, getTrendingNotes] =
    makeUseAxiosHook('search', {
      manual: true,
    })(
      {
        url: 'trending-notes',
        method: 'GET',
      },
      {
        university: universityId,
        specialization: specializationId,
        subject: subjectObj._id,
      }
    );
  useEffect(() => {
    if (error) {
      return;
    }
    if (trendingData && !error) {
      setTrendingNotes({
        ...trendingNotes,
        data: [
          ...trendingNotes.data,
          ...trendingData.data.map((d) => d._source),
        ],
        hasMore: false,
        loading: loading,
      });
    }
  }, [error, trendingData, user]);

  const loadMore = () => {
    getTrendingNotes();
  };

  useEffect(() => {
    getTrendingNotes();
  }, []);

  return (
    <View style={[sx({ width: '100%' })]}>
      <View
        style={[
          sx({
            flex: 1,
            flexDirection: ['column', 'row'],
            paddingLeft: [0, 5],
            paddingRight: [0, 5],
            marginLeft: [0, 25],
            marginRight: [0, 25],
            backgroundColor: ['#F0F0F0'],
            justifyContent: 'center',
          }),
        ]}
      >
        <View style={[sx({ width: [width, width - 80, width - 200] })]}>
          <View
            style={[
              sx({
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 3,
                paddingBottom: 5,
              }),
            ]}
          >
            <Highlight
              textStyle={{
                fontWeight: 'bold',
                fontSize: 25,
                color: '#000',
                overflow: 'hidden',
              }}
              style={{ alignSelf: 'center' }}
            >
              {subjectObj?.name}
            </Highlight>
          </View>

          <View
            style={[
              sx({
                minHeight: [150],
                borderRadius: 5,
              }),
            ]}
          >
            <View
              style={{ flex: 1, height: 300, marginTop: 8, marginBottom: 24 }}
            >
              <HorizontalSlider
                url={'search-syllabus'}
                dataKey="data"
                initialData={chapters}
                displayCount={30}
                renderItemCard={(d: any) => {
                  return (
                    <RenderCourseCard
                      id={d.id || d._id}
                      name={`${d.name}`}
                      shortName={d.shortName}
                      href={`/${d.pageUrl}`}
                      key={`s-${d.id || d._id}`}
                    />
                  );
                }}
                convertData={(d: any) => {
                  return {
                    ...d._source,
                  };
                }}
                apiClient="search"
                user={user}
                extraQuery={{
                  type: 'chapter',
                  instituteId: universityId,
                  specializationId,
                  subjectId: subjectObj._id 
                }}
              />
            </View>
          </View>
        </View>
      </View>
      <View style={[sx({ justifyContent: 'center', alignItems: 'center' })]}>
        <Text
          style={[
            sx({
              fontSize: 25,
              fontWeight: 'bold',
              marginTop: 5,
              marginBottom: 4,
            }),
          ]}
        >
          Trending Notes for {subjectObj?.name}
        </Text>

        <Row style={{ flex: 1 }}>
          <FlatGrid
            style={{ width: width }}
            itemDimension={sx({ width: [200, 500] }).width}
            contentContainerStyle={{ justifyContent: 'space-between' }}
            scrollEnabled={true}
            keyExtractor={(item: any) => (item.id ? item.id : item._id)}
            data={trendingNotes.data}
            renderItem={({ item }: { item: any }) => {
              return (
                <MaterialCard
                  {...item}
                  showCorner={false}
                  id={item.id ? item.id : item._id}
                  _id={item.id ? item.id : item._id}
                  src={item.thumb}
                  name={item?.materials?.name}
                  style={{ maxWidth: 500 }}
                  href={`/m/${item.id || item._id}`}
                  material={item}
                />
              );
            }}
          />
        </Row>
      </View>
      {trendingNotes.hasMore &&
        !trendingNotes.loading &&
        !!trendingNotes.data.length && (
          <View style={[sx({ flex: 1, alignItems: 'center' })]}>
            <Button
              onPress={loadMore}
              style={[sx({ width: 100, borderColor: '$primary' })]}
              //variantType="outlined"
            >
              View More
            </Button>
          </View>
        )}
      {pageDescription || subjectObj?.description ? (
        <View style={[sx({ margin: 4, padding: 3 })]}>
          <ShadowEffectCard>
            <QuillViewer html={pageDescription || subjectObj?.description} />
          </ShadowEffectCard>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

export const SubjectScreen = connect(
  getUser,
  {}
)(SearchSubjectComponent);
