import { Text, useSx, View } from 'dripsy';
import React, { useEffect, useRef, useState } from 'react';
import { FlatList } from 'react-native';
import { makeUseAxiosHook } from 'app/redux/store';
import Tabs from 'app/components/tabs/tabsFlatList';
import { useRouter } from 'solito/router';
import { Entypo } from '@expo/vector-icons';
import { QuillViewer } from 'app/components/quill-viewer';
import { H3 } from 'app/components/typography/h3';
import { H1 } from 'app/components/typography/h1';
import { useDim } from 'app/redux/hooks/useDim';

export const PageScreen = ({
  pageType,
  slug,
  id,
  pageTitle,
  contentPages = [],
}): JSX.Element => {
  const { width } = useDim();
  const sx = useSx();
  const { push } = useRouter();
  const ref = useRef<any>();
  const tabRef = useRef<any>();
  const [selectedItem, setSelectedItem] = useState(0);
  const { height } = useDim();

  const [{ data: contentPagesListData }, getContentPageListData] =
    makeUseAxiosHook('academic', { manual: true })(
      {
        url: 'web-pages',
        method: 'GET',
      },
      {
        type: pageType,
      }
    );

  useEffect(() => {
    if (id) {
      getContentPageListData({
        params: {
          type: 'page',
          deleted: false,
          enableContent: true,
          $limit: 50,
        },
      });
    }
  }, [id]);

  const pagesListData = contentPagesListData?.data;
  const pageData = contentPages;

  const tabList = pagesListData?.map((item: any) => {
    return {
      ...item,
      label: item.pageTitle,
      key: item.url,
    };
  });

  useEffect(() => {
    const index = pagesListData?.findIndex((e) => e.url === slug);
    if(index >= 0) {
      tabRef?.current?.scrollToIndex({
        index,
        animated: true,
        viewPosition: 0,
      });
    }
  }, [pagesListData, width]);

  const onTitlePress = (index: any) => {
    setSelectedItem(index);

    ref?.current?.scrollToItem({
      item: pageData[index],
      animated: true,
      viewPosition: 0,
    });
  };

  return (
    <>
      <View
        style={sx({
          flex: 1,
          flexDirection: 'row',
          mx: [1, 2],
        })}
      >
        <View
          style={[
            sx({
              backgroundColor: 'white',
              borderRadius: 10,
              shadowColor: '#000',
              zIndex: [9, 9, 0],
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 6,
              elevation: 5,
              my: 3,
              mx: 2,
              flex: 1,
              display: ['none', null, null, 'flex'],
            }),
          ]}
        >
          <H1
            style={[
              sx({
                marginTop: 4,
                marginBottom: 18,
                paddingLeft: 22,
                fontSize: 24,
                // fontWeight: 700,
              }),
            ]}
          >
            {pageTitle}
          </H1>
          <FlatList<any>
            data={[...pageData].sort(
              (a: any, b: any) => a.serialNo - b.serialNo
            )}
            style={[
              sx({
                maxHeight: height - 200,
                paddingBottom: height - 1000,
              }),
            ]}
            renderItem={({ item, index = 0 }) => (
              <View
                style={{
                  marginVertical: 6,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: 14,
                }}
              >
                <Text
                  style={sx({
                    fontSize: 18,
                    py: 2,
                    borderLeftWidth: 4,
                    borderColor:
                      selectedItem === index ? '$secondary' : 'white',
                    paddingLeft: 18,
                    flex: 1,
                    color: selectedItem === index ? '$primary' : '#7C91A7',
                    // fontWeight: selectedItem === index ? 'bold' : 'normal',
                    lineHeight: 20,
                  })}
                  onPress={() => onTitlePress(index)}
                >
                  {item.heading}
                </Text>
                <Entypo
                  name="chevron-right"
                  size={24}
                  color={selectedItem === index ? '#3495BC' : 'white'}
                />
              </View>
            )}
            keyExtractor={({ item }) => item?._id}
            extraData={selectedItem}
            
          />
        </View>

        <View
          style={[
            sx({
              backgroundColor: 'white',
              borderRadius: 10,
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 6,
              elevation: 5,
              margin: 3,
              flex: 4,
              fontSize: 24,
              // fontWeight: 'bold',
              padding: [2, 3],
              height: height - 90,
            }),
          ]}
        >
          <View sx={{ position: 'relative' }}>
            <Tabs
              ref={tabRef}
              navButtonNextStyle={sx({
                width: 20,
                height: 20,
                right: 5.1,
              })}
              navButtonPrevStyle={sx({
                width: 20,
                height: 20,
                left: 5.1,
              })}
              showsHorizontalScrollIndicator={false}
              tabList={tabList || []}
              key={pagesListData?.pageTitle}
              tabStyles={sx({
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
                py: 0,
                px: 0,
              })}
              tabRowStyle={sx({
                backgroundColor: '#647C97',
                borderRadius: 8,
                overflow: 'hidden',
                px: 4,
              })}
              headerTextStyle={sx({
                fontSize: [14, null, null, 16],
                // fontWeight: 'normal',
                color: '#fff',
                px: [3, 3, 5],
              })}
              activeHeaderStyle={sx({
                fontSize: [14, null, null, 16],
                // fontWeight: 'normal',
                backgroundColor: '$secondary',
                width: '100%',
                px: [3, 3, 5],
                py: [2, 3],
                textAlign: 'center',
                borderRadius: 8,
                color: 'white',
              })}
              contentStyle={{
                maxHeight: height - 240,
              }}
              value={slug}
              onChange={(value) => {
                const asValue = value.split('/');
                const asUrl = {
                  1:'/[slug]',
                  2:'/[slug]/[sub-slug]',
                  3:'/[slug]/[sub-slug]/[nested-slug]'
                };
                push(`${asUrl[asValue.length]}`, `/${value}`, {
                  shallow: true,
                });
              }}
              activeColor={''}
              showBottomBorder={false}
            />
          </View>

          <FlatList
            style={[
              sx({
                maxHeight: [height - 170, null, null, height - 200],
                paddingBottom: height - 1000,
              }),
            ]}
            ref={ref}
            ListHeaderComponent={() => (
              <H3
                style={sx({
                  display: ['flex', null, null, 'none'],
                  fontSize: 18,
                  my: 0,
                })}
              >
                {pageTitle}
              </H3>
            )}
            horizontal={false}
            initialNumToRender={2}
            initialScrollIndex={0}
            data={[...pageData].sort(
              (a: any, b: any) => a.serialNo - b.serialNo
            )}
            renderItem={({ item }: { item: any }) => (
              <View key={item?._id}>
                <H3
                  style={sx({
                    display: ['flex', null, null, 'none'],
                    fontSize: [15, null, 16],
                  })}
                >
                  {item.heading}
                </H3>
                <Text
                  style={sx({
                    flex: 1,
                    fontSize: [15, null, 16],
                    lineHeight: 20,
                    paddingRight: 12,
                    my: [0, 2],
                    color: '$textColorTwo',
                  })}
                >
                  <QuillViewer html={item.content} />
                </Text>
              </View>
            )}
          />
        </View>
      </View>
    </>
  );
};
