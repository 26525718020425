import { Row, styled, Text, useSx, View } from 'dripsy';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FlatGrid as RNFlatGrid } from 'react-native-super-grid';
import { MaterialCard } from 'app/components/card/material-card';
import { Highlight } from 'app/components/typography/highlight';
import { makeUseAxiosHook } from 'app/redux/store';
import { HorizontalSlider } from '../../../components/horizontal-slider';
import { Button } from 'app/components/button/common-button';
import { getContrastYIQ, stringToColour } from 'app/util/helper';
import { ShadowEffectCard } from 'app/features/upload-page/accordion-section';
import { QuillViewer } from 'app/components/quill-viewer';
import { H1 } from 'app/components/typography/h1';
import { useDim } from 'app/redux/hooks/useDim';
import { getUser } from '../../../redux/memoizedSelectors';

export const CardHeadings = styled(Text)({
  fontWeight: 'bold',
  fontSize: 16,
  padding: 2,
});

export const FlatGrid = styled(RNFlatGrid)({});

const SearchUniversityComponent = ({
  user,
  universityObj,
  coursesOffered = [],
  pageDescription
}: {
  user: any;
  universityObj: any;
  coursesOffered?: any[];
  pageDescription?:string
}) => {
  const sx = useSx();
  const { width } = useDim();

  const [trendingNotes, setTrendingNotes] = useState<any>({
    data: [],
    loading: false,
    hasMore: true,
  });

  const [{ data: trendingData, loading, error }, getTrendingNotes] =
    makeUseAxiosHook('search', {
      manual: true,
    })(
      {
        url: 'trending-notes',
        method: 'GET',
      },
      { institute: universityObj._id }
    );
  useEffect(() => {
    if (error) {
      return;
    }
    if (trendingData && !error) {
      setTrendingNotes({
        ...trendingNotes,
        data: [
          ...trendingNotes.data,
          ...trendingData.data.map((d) => d._source),
        ],
        hasMore: false,
        loading: loading,
      });
    }
  }, [error, trendingData, user]);

  const loadMore = () => {
    getTrendingNotes();
  };

  useEffect(() => {
    if (universityObj._id) {
      getTrendingNotes();
    }
  }, [universityObj._id]);

  return (
    <View style={[sx({ width: '100%' })]}>
      <View
        style={[
          sx({
            flex: 1,
            flexDirection: ['column', 'row'],
            paddingLeft: [0, 5],
            paddingRight: [0, 5],
            marginLeft: [0, 25],
            marginRight: [0, 25],
            backgroundColor: ['#F0F0F0'],
            justifyContent: 'center',
          }),
        ]}
      >
        <View style={[sx({ width: [width, width - 80, width - 200] })]}>
          <View
            style={[
              sx({
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 3,
                paddingBottom: 5,
              }),
            ]}
          >
            <Highlight
              textStyle={{
                fontWeight: 'bold',
                fontSize: 25,
                color: '#000',
                overflow: 'hidden',
              }}
              style={{ alignSelf: 'center' }}
            >
              {universityObj?.name}
            </Highlight>

            <Highlight
              highlightColor={['#00A79D', '#1D76BB']}
              style={{
                alignSelf: 'center',
                paddingTop: 3,
              }}
              textStyle={{
                fontWeight: 'bold',
                fontSize: 18,
                color: '#fff',
                overflow: 'hidden',
              }}
              type="brush"
            >
              Courses We Offer
            </Highlight>
          </View>

          <View
            style={[
              sx({
                minHeight: [150],
                borderRadius: 5,
              }),
            ]}
          >
            <View
              style={{ flex: 1, height: 300, marginTop: 8, marginBottom: 24 }}
            >
              <HorizontalSlider
                url={'get-institute-syllabus'}
                initialData={coursesOffered}
                dataKey="data"
                displayCount={30}
                renderItemCard={(d: any) => {
                  return (
                    <RenderCourseCard
                      id={d._id || d.id}
                      name={`${d.name}`}
                      shortName={d.shortName}
                      href={`/${d.pageUrl}`}
                      key={`s-${d._id}`}
                    />
                  );
                }}
                convertData={(d: any) => {
                  return {
                    ...d._source,
                  };
                }}
                apiClient="search"
                user={user}
                extraQuery={{ instituteId: universityObj?._id }}
              />
            </View>
          </View>
        </View>
      </View>
      <View style={[sx({ justifyContent: 'center', alignItems: 'center' })]}>
        <Text
          style={[
            sx({
              fontSize: 25,
              fontWeight: 'bold',
              marginTop: 5,
              marginBottom: 4,
            }),
          ]}
        >
          Trending Notes for {universityObj?.name}
        </Text>

        <Row style={{ flex: 1 }}>
          <FlatGrid
            style={{ width: width }}
            itemDimension={sx({ width: [200, 500] }).width}
            contentContainerStyle={{ justifyContent: 'space-between' }}
            scrollEnabled={true}
            keyExtractor={(item: any) => (item.id ? item.id : item._id)}
            data={trendingNotes.data}
            renderItem={({ item }: { item: any }) => {
              return (
                <MaterialCard
                  {...item}
                  showCorner={false}
                  _id={item.id ? item.id : item._id}
                  id={item.id ? item.id : item._id}
                  src={item.thumb}
                  name={item?.materials?.name}
                  style={{ maxWidth: 500 }}
                  href={`/m/${item._id || item.id}`}
                  material={item}
                />
              );
            }}
          />
        </Row>
      </View>
      {trendingNotes.hasMore &&
        !trendingNotes.loading &&
        !!trendingNotes.data.length && (
          <View style={[sx({ flex: 1, alignItems: 'center' })]}>
            <Button
              onPress={loadMore}
              style={[sx({ width: 100, borderColor: '$primary' })]}
              //variantType="outlined"
            >
              View More
            </Button>
          </View>
        )}
      {(pageDescription  || (universityObj.description !== '<p><br /></p>' && universityObj?.description)) ? (
        <View style={[sx({ margin: 4, padding: 3 })]}>
          <ShadowEffectCard>
            <QuillViewer html={pageDescription || universityObj?.description} />
          </ShadowEffectCard>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

export const UniversityScreen = connect(
  getUser,
  {}
)(SearchUniversityComponent);

export const RenderCourseCard = (props: any) => {
  const sx = useSx();
  return (
    <View
      style={{
        padding: 10,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginVertical: 8,
        marginHorizontal: 20,
        minHeight: 100,
        minWidth: 130,
        maxWidth: 130,
        backgroundColor: '#fff',
        borderRadius: 10,
      }}
      {...props}
    >
      <View
        style={[
          {
            backgroundColor: stringToColour(`${props.id}`),
            borderRadius: 1000,
            padding: 20,
            margin: 10,
            justifyContent: 'center',
            alignItems: 'center',
          },
          sx({ width: [60, 60], height: [60, 60] }),
        ]}
      >
        <Text
          style={{
            fontWeight: 'bold',
            fontSize: 16,
            color: getContrastYIQ(stringToColour(`${props.name}`).substring(1)),
          }}
        >
          {(
            props.name
              .match(/\b(\w)/g)
              .join('')
              .substring(0, 3) as string
          ).toUpperCase()}
        </Text>
      </View>
      <H1 style={{ fontSize: 12, textAlign: 'center' }}>{props.name}</H1>
    </View>
  );
};
