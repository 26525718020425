import React, { useState } from 'react';
import { View, useSx, FlatList } from 'dripsy';
import { useDim } from 'app/redux/hooks/useDim';
import { Empty } from 'app/components/Empty';
import { Highlight } from 'app/components/typography/highlight';
import { QuillViewer } from 'app/components/quill-viewer';
import { H3 } from 'app/components/typography/h3';
import { H4 } from 'app/components/typography/h4';
import { H5 } from 'app/components/typography/h5';
import { Button } from 'app/components/button/common-button';
import { useRouter } from 'solito/router';
import moment from 'moment';
import { Icon } from 'app/components/icon/icon';
import Tooltip from 'app/components/tooltip';

function CompetitiveExams({ examSyllabus }) {
  const sx = useSx();
  const { width } = useDim();
  // const { push } = useRouter();
  const [numRows, setNumRows] = useState(2);

  const handleViewMore = () => {
    setNumRows(numRows + 1);
  };

  return (
    <View style={{ marginHorizontal: 10 }}>
      <Highlight
        textStyle={{
          fontWeight: 'bold',
          fontSize: 25,
          color: '#000',
          overflow: 'hidden',
        }}
        style={{ alignSelf: 'center', marginVertical: 15 }}
      >
        {`Competitive exams under ${examSyllabus?.object?.name} syllabus`}
      </Highlight>
      <H3>Description</H3>
      <View
        style={[
          sx({
            paddingLeft: [0, 3],
            marginBottom: 2,
          }),
        ]}
      >
        <QuillViewer html={examSyllabus?.description || examSyllabus?.object?.description} />
      </View>

      <FlatList
        data={examSyllabus?.exams.slice(0, numRows * 4)}
        contentContainerStyle={sx({
          paddingY: 2,
          marginLeft: 'auto',
          marginRight: 'auto',
          flex: 1,
        })}
        numColumns={width >= 1080 ? 4 : width > 600 ? 2 : 1}
        renderItem={({ item, index }) => {
          return <ExamCards key={index} examData={item} />;
        }}
        ListEmptyComponent={() =>
          !examSyllabus?.exams?.length ? (
            <View
              style={sx({
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              <Empty noDataFoundText="No exams found!" />
            </View>
          ) : (
            <></>
          )
        }
      />
      {numRows * 4 < examSyllabus?.exams.length && (
        <View style={{ alignItems: 'center' }}>
          <Button onPress={handleViewMore} style={{ marginTop: 10, width: 150 }}>
            View More
          </Button>
        </View>
      )}
    </View>
  );
}

export default CompetitiveExams;

const ExamCards = ({ examData }) => {
  const { push } = useRouter();
  

  return (
    <View
      style={{
        margin: 8,
        minHeight: 170,
        padding: 10,
        borderRadius: 6,
        backgroundColor: '$white',
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        justifyContent: 'space-between',
        maxWidth: 360,
      }}
    >
      <Tooltip content={examData.name}
        position="bottom"
        style={{
          minWidth: 200,
          position: 'absolute',
          top: 35
        }}
      >
        <H4 numberOfLines={1}>{examData.name}</H4>
      </Tooltip>
      <H4 style={{ color: 'grey', marginBottom: 2 }}>
        {moment(examData?.creationTime)?.format('DD MMM YYYY, h:mm a')}
      </H4>
      <View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 5,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Icon
              height={15}
              width={15}
              style={{ marginRight: 8 }}
              name="question"
            />
            <H5>Questions : {examData?.numberOfQuestions || 0}</H5>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Icon
              height={15}
              width={15}
              style={{ marginRight: 8 }}
              name="duration"
            />
            <H5>Duration : {examData?.duration}</H5>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Icon
              height={15}
              width={15}
              style={{ marginRight: 8 }}
              name="marks"
            />
            <H5>Marks : {examData?.totalMarks}</H5>
          </View>
        </View>
        <Button
          style={{
            minWidth: 75,
            minHeight: 25,
            borderRadius: 30,
            margin: 0,
          }}
          onPress={() =>
            push('/appear-exam/[pageType]', `/appear-exam/${examData?._id}`)
          }
        >
          Appear
        </Button>
      </View>
    </View>
  );
};
